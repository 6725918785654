import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import Bio from "../components/Bio/bio";

class Landing extends React.Component {
    render() {
        return (
          <Layout>

            <div className="landing-container">
              <Helmet title={config.siteTitle} />
              <SEO />
              <Bio showFull={true} />
            </div>
          </Layout>
        );
    }
}

export default Landing;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
    query LandingQuery {
        allMarkdownRemark(filter: {fields: {slug: {regex: "^/blog/"}}},sort: { fields: [fields___date], order: DESC }) {
            edges {
                node {
                    fields {
                        slug
                        date
                    }
                    excerpt
                    timeToRead
                    frontmatter {
                        title
                        tags
                        cover
                        date
                    }
                }
            }
        }
    }
`;
